import React from 'react';
import './Banner.css';

const Banner = () => {
  // Function to handle click, renamed for clarity
  const handleBannerClick = () => {
    window.open('https://bumpups.com/', '_blank'); // Opens in a new tab
  };

  return (
    <div className="banner" onClick={handleBannerClick}>
      <div className="announcement">
        We're excited to share that _cafe_ AI's investment, Bumpups, has secured funding from Google! 🌲
      </div>
    </div>
  );
};

export default Banner;
