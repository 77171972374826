import React, { useState, useEffect } from 'react';
import './TopFold.css';
import webcafeCup from '../assets/images/white-cafe.png';
// import playIcon from '../assets/images/playbutton-v1.png';
import bumpupLogo from '../assets/images/bumpups-dark.jpg';
import VideoModal from '../components/VideoModal';

import Banner from '../components/Banner';


const TopFold = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Disable/Enable scroll based on modal state
  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = 'hidden'; // Disable scroll
    } else {
      document.body.style.overflow = 'auto'; // Enable scroll
    }

    // Clean up to reset overflow on component unmount
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isModalOpen]);

  const openLinkInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="topfold-container">
       <Banner />
      <div className="nav-hero-wrapper">
        {/* Navbar */}
        <nav className="navbar">
          <div className="logo-container">
            <img src={webcafeCup} alt="WebCafe Logo" className="logo" />
          </div>

          <div className="nav-buttons">
            <button
              className="content-catalog-btn"
              onClick={() => openLinkInNewTab('https://www.youtube.com/@Corbin_Brown/playlists')}
            >
              Content Catalog
            </button>
            <button
              className="download-site-btn"
              onClick={() =>
                openLinkInNewTab(
                  'https://www.youtube.com/watch?v=-tnPCI5RdNA&list=PLJrzt4ameiaPTLN4LXiv1Y7ONKuaj7Q9O'
                )
              }
            >
              Take This Website
            </button>
          </div>
        </nav>

        {/* Hero Section */}
        <div className="hero-section">
            <div className="hero-title">
              {'{ automate '}<strong>everything.</strong>{' }'}
            </div>
            <p className="hero-subtitle">
              Based in San Francisco, _cafe_ creates and invests in AI and SaaS solutions.
            </p>
            <div className="cta-buttons">
              <a 
                href="https://bumpups.com/" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="watchdemo-btn"
              >
                <img src={bumpupLogo} alt="Play Icon" className="play-icon" />
                <div className="demo-text">
                  <span>
                    <b>active investment</b>
                  </span>
                  <span className="demo-duration">bumpups.com</span>
                </div>
              </a>
            </div>
          </div>

      </div>

      {/* Video Modal */}
      <VideoModal show={isModalOpen} onClose={handleModalClose} />
    </div>
  );
};

export default TopFold;
